<nz-table
  #nzTable nzBordered="true"
  [nzData]="listData" nzSize="small"
  [nzTotal]="totalCount" [nzFrontPagination]="false"
  [nzShowPagination]="totalCount > listData.length"
  [nzPageSize]="limit" [nzPageIndex]="pageIndex"
  [nzLoading]="isLoading"
  [nzLoadingIndicator]="tplLoading"
  [nzNoResult]="tplNoData"
  [nzSize]="'default'"
  [nzScroll]="{y: tableHeight + 'px'}"
  (nzPageIndexChange)="onDataListPageChanged($event)">
  <ng-template #tplNoData>
    <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
  </ng-template>
  <ng-template #tplLoading>
    <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  </ng-template>
  <thead (nzSortOrderChange)="onSortOrderChange($event)">
    <tr>
      <th nzWidth="200px">Customer</th>
      <th nzWidth="250px">Request By</th>
      <th nzWidth="250px">Request At</th>
      <th nzWidth="350px">Pickup Location</th>
      <th nzWidth="100px">Number Of Pallets</th>
      <th nzWidth="150px">Pickup Time</th>
      <th nzWidth="150px">Delivery Time</th>
      <th nzWidth="150px" *ngIf="hasAction">Action</th>
      <th nzWidth="150px" *ngIf="hasStatus">Status</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of listData; let i = index">
      <td nzWidth="200px">
        {{ item.client?.name || 'N/A'}}
      </td>
      <td nzWidth="250px">
        <div>{{ item.displayInfo?.requestByName }}</div>
        <div class="request-by-email">{{ item.displayInfo?.requestByEmail }}</div>
        <div *ngIf="item.displayInfo?.requestByPhone" class="request-by-email">{{ item.displayInfo?.requestByPhone }}</div>
      </td>
      <td nzWidth="250px">
        {{ item.displayInfo?.createRequestTime }}
      </td>
      <td nzWidth="350px">
        <div>{{ item.displayInfo?.pickLocationName }}</div>
        <div class="pick-address">{{ item.displayInfo?.pickAddress }}</div>
      </td>
      <td nzWidth="100px">
        {{ item.displayInfo?.numberOfPallets }}
      </td>
      <td nzWidth="150px">
        {{ item.displayInfo?.pickDatetime || '-' }}
      </td>
      <td nzWidth="150px">
        {{ item.displayInfo?.dropDatetime || '-'}}
      </td>
      <td nzWidth="150px" *ngIf="hasAction">
        <ng-container *ngFor="let action of item.actions">
          <a *ngIf="action.isPermission" (click)="action.handler(item)">
            {{action.name}}
          </a>
        </ng-container>
      </td>
      <td nzWidth="150px" *ngIf="hasStatus">
        <div>
          <button *ngIf="item.review?.isApproved" nz-button class="btn btn-approved">Approved</button>
          <button *ngIf="item.review && item.review.isApproved == false" nz-button class="btn btn-rejected">Rejected</button>
          <button *ngIf="!item.review" nz-button class="btn">Need Review</button>
        </div>
        <div *ngIf="item.review?.isApproved && item.orderId && item.order?.warpId" style="margin-top: 5px;">
          <a [routerLink]="[routeAdminShipmentList, item.orderId]" target="_blank">
            {{showOrderWarpId(item.order.warpId)}}
          </a>
        </div>
      </td>
    </tr>
  </tbody>
</nz-table>